import { ChangeEvent, useContext, useEffect, useState } from "react"
import { Loader } from "../Loader"
import { GlobalContext } from "../../context/GlobalContext"
import { PopupInfo } from "../PopupInfo"

export const AccountForm = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupText, setOpenPopupText] = useState("false")
    const [form, setForm] = useState({ 
        name: "",
        surname: "",
        minName: "",
        phone: "",
        sertEmail: "",
        password: "",
        passwordConf: ""
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        auth.user.updateData(form).then(function(response){
            setForm({ 
                ...form, 
                password: "",
                passwordConf: ""
            })
            setOpenPopupText(response.msg)
            setOpenPopup(true)
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{
        setForm({
            name: auth.user.name,
            surname: auth.user.surname,
            minName: auth.user.minName,
            sertEmail: auth.user.sertEmail,
            phone: auth.user.phone,
            password: "",
            passwordConf: ""
        })
        setLoad(false)
    },[])

    if(load)
        return(
            <Loader />
        )

    return(
        <>
            <form onSubmit={handleSubmit}>
                <label>
                    <input type="text" maxLength={255} placeholder="Фамилия" required name="surname" value={form.surname} onChange={changeHandler}  />
                </label>
                <label>
                    <input type="text" maxLength={255} placeholder="Имя" required name="name" value={form.name} onChange={changeHandler}  />
                </label>
                <label>
                    <input type="text" maxLength={255} placeholder="Отчество" name="minName" value={form.minName} onChange={changeHandler}  />
                </label>
                <label>
                    <input type="text" maxLength={25} placeholder="Номер телефона" required name="phone" value={form.phone} onChange={changeHandler}  />
                </label>

                <div className="account-pass">
                    <h2 className="block-title">Email для получения сертификатов</h2>
                <label>
                    <input type="text" maxLength={255} placeholder="Email для получения сертификатов" required name="sertEmail" value={form.sertEmail} onChange={changeHandler}  />
                </label>
                </div> 


                <div className="account-pass">
                    <h2 className="block-title">Смена пароля</h2>
                    <label>
                        <input type="password" maxLength={40} placeholder="Пароль" name="password" value={form.password} onChange={changeHandler}  />
                    </label>
                    <label>
                        <input type="password" maxLength={40} placeholder="Повторите пароль" name="passwordConf" value={form.passwordConf} onChange={changeHandler}  />
                    </label>
                </div>
                <div>
                    <button>Сохранить</button>
                </div>
            </form>
            {
                openPopup ? (
                    <PopupInfo text={openPopupText} close={setOpenPopup} />
                ):""
            }
        </>
    )
}